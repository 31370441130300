<template>
  <div>
    <div class="title">学习报告</div>
    <div class="tab">
      <div @click="onItemTab(1)" :class="active == 1 ? 'item active' : 'item'">
        今天
      </div>
      <div @click="onItemTab(2)" :class="active == 2 ? 'item active' : 'item'">
        本周
      </div>
      <div @click="onItemTab(3)" :class="active == 3 ? 'item active' : 'item'">
        本月
      </div>
      <div @click="onItemTab(4)" :class="active == 4 ? 'item active' : 'item'">
        本年
      </div>
    </div>
    <div class="statistics">
      <div class="item1">
        <img :src="userInfo.pic" alt="" />
        <div class="item1_1">
          <div>学员帐号：</div>
          <div class="item1_1_1">
            {{ userInfo.username || "暂无信息，去完善" }}
          </div>
        </div>
      </div>
      <div class="item2">
        <div class="item2_1">已学习课程</div>
        <div class="item2_2">{{ detail.class || 0 }} <span>门</span></div>
      </div>
      <div class="item3">
        <div class="item3_1">已签到天数</div>
        <div class="item3_2">{{ detail.sign || 0 }}<span>天</span></div>
      </div>
    </div>
    <div class="report">
      <div class="report_title">学习报告</div>
      <div class="report_list">
        <div class="report_item">
          <img src="../../assets/xuexishichang-icon.png" alt="" />学习时长
          <span>{{ detail.classHour.hour ? detail.classHour.hour : "0" }}</span
          >小时<span>{{
            detail.classHour.min ? detail.classHour.min : "00"
          }}</span
          >分
        </div>
        <div class="report_item">
          <img src="../../assets/yinpin-icon.png" alt="" />学习音频
          <span>{{ detail.audio }}</span
          >个
        </div>
        <div class="report_item">
          <img src="../../assets/guankanshipin-icon.png" alt="" />观看视频
          <span>{{ detail.video }}</span
          >个
        </div>
        <div class="report_item">
          <img src="../../assets/zuoti-icon.png" alt="" />做题总数
          <span>{{ detail.project }}</span
          >题
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      detail: {},
      active: 1,
    };
  },
  methods: {
    ...mapActions(["getPresentation", "getstudyReportFromDay_x"]),
    // 学习报告切换
    onItemTab(val) {
      this.active = val;
      this.getstudyReportFromDay_x({
        student_id: this.userInfo.id,
        type: val,
      }).then((res) => {
        if (res.successCode == 200) {
          this.detail = res.responseBody;
        }
      });
    },
  },
  mounted() {
    // 学习信息
    this.getstudyReportFromDay_x({
      student_id: this.userInfo.id,
      type: 1,
    }).then((res) => {
      if (res.successCode == 200) {
        this.detail = res.responseBody;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.tab {
  margin-top: 15px;
  padding: 0 47px;
  box-sizing: border-box;
  display: flex;
  height: 92px;
  line-height: 92px;
  background: #ffffff;
  .item {
    cursor: pointer;
    position: relative;
    padding: 0 30px;
    margin: 0 30px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a7a7a7;
  }
  .active {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    &::before {
      position: absolute;
      content: "";
      display: block;
      width: 96px;
      height: 4px;
      background: #137cfb;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
}
.statistics {
  padding: 33px 52px;
  box-sizing: border-box;
  display: flex;
  margin-top: 14px;
  height: 174px;
  background: #ffffff;
  .item1 {
    display: flex;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    img {
      vertical-align: middle;
      width: 90px;
      height: 90px;
    }
    .item1_1 {
      margin-top: 17px;
      margin-left: 24px;
    }
    .item1_1_1 {
      margin-top: 17px;
    }
  }
  .item2 {
    margin-left: 103px;
    .item2_1 {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .item2_2 {
      text-align: center;
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }
  }
  .item3 {
    margin-left: 103px;
    .item3_1 {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .item3_2 {
      text-align: center;
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }
  }
}
.report {
  margin-top: 18px;
  padding: 44px 47px;
  box-sizing: border-box;
  background-color: #fff;
  .report_title {
    margin-bottom: 72px;
    font-size: 21px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .report_list {
    display: flex;
    flex-wrap: wrap;

    .report_item {
      margin-bottom: 120px;
      width: 50%;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      span {
        margin-left: 10px;
        font-size: 30px;
        font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        color: #ff743e;
      }
      img {
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
}
</style>
